/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { downloadTransferPreview } from '../fn/download/download-transfer-preview';
import { DownloadTransferPreview$Params } from '../fn/download/download-transfer-preview';
import { prepareDownload } from '../fn/download/prepare-download';
import { PrepareDownload$Params } from '../fn/download/prepare-download';
import { TransferDownloadUrl } from '../models/transfer-download-url';

@Injectable({ providedIn: 'root' })
export class DownloadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `prepareDownload()` */
  static readonly PrepareDownloadPath = '/protected/downloads/prepare';

  /**
   * Prepare Transfer Download.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `prepareDownload()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  prepareDownload$Response(params?: PrepareDownload$Params, context?: HttpContext): Observable<StrictHttpResponse<TransferDownloadUrl>> {
    return prepareDownload(this.http, this.rootUrl, params, context);
  }

  /**
   * Prepare Transfer Download.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `prepareDownload$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  prepareDownload(params?: PrepareDownload$Params, context?: HttpContext): Observable<TransferDownloadUrl> {
    return this.prepareDownload$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransferDownloadUrl>): TransferDownloadUrl => r.body)
    );
  }

  /** Path part for operation `downloadTransferPreview()` */
  static readonly DownloadTransferPreviewPath = '/protected/downloads/preview/{transferId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadTransferPreview()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadTransferPreview$Response(params: DownloadTransferPreview$Params, context?: HttpContext): Observable<StrictHttpResponse<TransferDownloadUrl>> {
    return downloadTransferPreview(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadTransferPreview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadTransferPreview(params: DownloadTransferPreview$Params, context?: HttpContext): Observable<TransferDownloadUrl> {
    return this.downloadTransferPreview$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransferDownloadUrl>): TransferDownloadUrl => r.body)
    );
  }

}
