/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AddedTransfer } from '../models/added-transfer';
import { addTransfer } from '../fn/transfer/add-transfer';
import { AddTransfer$Params } from '../fn/transfer/add-transfer';
import { addTransferMedia } from '../fn/transfer/add-transfer-media';
import { AddTransferMedia$Params } from '../fn/transfer/add-transfer-media';
import { adminBrowseTransfers } from '../fn/transfer/admin-browse-transfers';
import { AdminBrowseTransfers$Params } from '../fn/transfer/admin-browse-transfers';
import { adminBrowseTransfersByUser } from '../fn/transfer/admin-browse-transfers-by-user';
import { AdminBrowseTransfersByUser$Params } from '../fn/transfer/admin-browse-transfers-by-user';
import { archiveTransfer } from '../fn/transfer/archive-transfer';
import { ArchiveTransfer$Params } from '../fn/transfer/archive-transfer';
import { browseTransfers } from '../fn/transfer/browse-transfers';
import { BrowseTransfers$Params } from '../fn/transfer/browse-transfers';
import { deleteTransferMedia } from '../fn/transfer/delete-transfer-media';
import { DeleteTransferMedia$Params } from '../fn/transfer/delete-transfer-media';
import { duplicateTransfer } from '../fn/transfer/duplicate-transfer';
import { DuplicateTransfer$Params } from '../fn/transfer/duplicate-transfer';
import { editCapabilities } from '../fn/transfer/edit-capabilities';
import { EditCapabilities$Params } from '../fn/transfer/edit-capabilities';
import { finalizeTransferFile } from '../fn/transfer/finalize-transfer-file';
import { FinalizeTransferFile$Params } from '../fn/transfer/finalize-transfer-file';
import { finalizeTransferMedia } from '../fn/transfer/finalize-transfer-media';
import { FinalizeTransferMedia$Params } from '../fn/transfer/finalize-transfer-media';
import { finalizeTransferPreview } from '../fn/transfer/finalize-transfer-preview';
import { FinalizeTransferPreview$Params } from '../fn/transfer/finalize-transfer-preview';
import { getTransferPrice } from '../fn/transfer/get-transfer-price';
import { GetTransferPrice$Params } from '../fn/transfer/get-transfer-price';
import { payTransfer } from '../fn/transfer/pay-transfer';
import { PayTransfer$Params } from '../fn/transfer/pay-transfer';
import { processTransfer } from '../fn/transfer/process-transfer';
import { ProcessTransfer$Params } from '../fn/transfer/process-transfer';
import { readSentTransfer } from '../fn/transfer/read-sent-transfer';
import { ReadSentTransfer$Params } from '../fn/transfer/read-sent-transfer';
import { readTransfer } from '../fn/transfer/read-transfer';
import { ReadTransfer$Params } from '../fn/transfer/read-transfer';
import { totalGain } from '../fn/transfer/total-gain';
import { TotalGain } from '../models/total-gain';
import { TotalGain$Params } from '../fn/transfer/total-gain';
import { Transfer } from '../models/transfer';
import { TransferFile } from '../models/transfer-file';
import { TransferList } from '../models/transfer-list';
import { TransferMedia } from '../models/transfer-media';
import { TransferPayUrl } from '../models/transfer-pay-url';
import { TransferPrice } from '../models/transfer-price';
import { verifyTransferSender } from '../fn/transfer/verify-transfer-sender';
import { VerifyTransferSender$Params } from '../fn/transfer/verify-transfer-sender';

@Injectable({ providedIn: 'root' })
export class TransferService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `browseTransfers()` */
  static readonly BrowseTransfersPath = '/private/transfers/browse';

  /**
   * Browse transfers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `browseTransfers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseTransfers$Response(params?: BrowseTransfers$Params, context?: HttpContext): Observable<StrictHttpResponse<TransferList>> {
    return browseTransfers(this.http, this.rootUrl, params, context);
  }

  /**
   * Browse transfers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `browseTransfers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseTransfers(params?: BrowseTransfers$Params, context?: HttpContext): Observable<TransferList> {
    return this.browseTransfers$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransferList>): TransferList => r.body)
    );
  }

  /** Path part for operation `adminBrowseTransfersByUser()` */
  static readonly AdminBrowseTransfersByUserPath = '/admin/transfers/{userId}/browse';

  /**
   * Admin Browse transfers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminBrowseTransfersByUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminBrowseTransfersByUser$Response(params: AdminBrowseTransfersByUser$Params, context?: HttpContext): Observable<StrictHttpResponse<TransferList>> {
    return adminBrowseTransfersByUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Admin Browse transfers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminBrowseTransfersByUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminBrowseTransfersByUser(params: AdminBrowseTransfersByUser$Params, context?: HttpContext): Observable<TransferList> {
    return this.adminBrowseTransfersByUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransferList>): TransferList => r.body)
    );
  }

  /** Path part for operation `adminBrowseTransfers()` */
  static readonly AdminBrowseTransfersPath = '/admin/transfers/browse';

  /**
   * Admin Browse transfers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminBrowseTransfers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminBrowseTransfers$Response(params?: AdminBrowseTransfers$Params, context?: HttpContext): Observable<StrictHttpResponse<TransferList>> {
    return adminBrowseTransfers(this.http, this.rootUrl, params, context);
  }

  /**
   * Admin Browse transfers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminBrowseTransfers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminBrowseTransfers(params?: AdminBrowseTransfers$Params, context?: HttpContext): Observable<TransferList> {
    return this.adminBrowseTransfers$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransferList>): TransferList => r.body)
    );
  }

  /** Path part for operation `readTransfer()` */
  static readonly ReadTransferPath = '/protected/transfers/{transferId}';

  /**
   * Read Transfer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readTransfer()` instead.
   *
   * This method doesn't expect any request body.
   */
  readTransfer$Response(params: ReadTransfer$Params, context?: HttpContext): Observable<StrictHttpResponse<Transfer>> {
    return readTransfer(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Transfer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readTransfer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readTransfer(params: ReadTransfer$Params, context?: HttpContext): Observable<Transfer> {
    return this.readTransfer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Transfer>): Transfer => r.body)
    );
  }

  /** Path part for operation `readSentTransfer()` */
  static readonly ReadSentTransferPath = '/private/transfers/{transferId}';

  /**
   * Read Sent Transfer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readSentTransfer()` instead.
   *
   * This method doesn't expect any request body.
   */
  readSentTransfer$Response(params: ReadSentTransfer$Params, context?: HttpContext): Observable<StrictHttpResponse<Transfer>> {
    return readSentTransfer(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Sent Transfer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readSentTransfer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readSentTransfer(params: ReadSentTransfer$Params, context?: HttpContext): Observable<Transfer> {
    return this.readSentTransfer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Transfer>): Transfer => r.body)
    );
  }

  /** Path part for operation `archiveTransfer()` */
  static readonly ArchiveTransferPath = '/private/transfers/{transferId}';

  /**
   * mark transfer as archived
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `archiveTransfer()` instead.
   *
   * This method doesn't expect any request body.
   */
  archiveTransfer$Response(params: ArchiveTransfer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return archiveTransfer(this.http, this.rootUrl, params, context);
  }

  /**
   * mark transfer as archived
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `archiveTransfer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  archiveTransfer(params: ArchiveTransfer$Params, context?: HttpContext): Observable<void> {
    return this.archiveTransfer$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `processTransfer()` */
  static readonly ProcessTransferPath = '/protected/transfers/{transferId}/process';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processTransfer()` instead.
   *
   * This method doesn't expect any request body.
   */
  processTransfer$Response(params: ProcessTransfer$Params, context?: HttpContext): Observable<StrictHttpResponse<AddedTransfer>> {
    return processTransfer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processTransfer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  processTransfer(params: ProcessTransfer$Params, context?: HttpContext): Observable<AddedTransfer> {
    return this.processTransfer$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddedTransfer>): AddedTransfer => r.body)
    );
  }

  /** Path part for operation `finalizeTransferFile()` */
  static readonly FinalizeTransferFilePath = '/protected/transfers/{transferId}/files/{fileId}/finalize';

  /**
   * Finalize transfer file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `finalizeTransferFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  finalizeTransferFile$Response(params: FinalizeTransferFile$Params, context?: HttpContext): Observable<StrictHttpResponse<TransferFile>> {
    return finalizeTransferFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Finalize transfer file.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `finalizeTransferFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  finalizeTransferFile(params: FinalizeTransferFile$Params, context?: HttpContext): Observable<TransferFile> {
    return this.finalizeTransferFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransferFile>): TransferFile => r.body)
    );
  }

  /** Path part for operation `finalizeTransferMedia()` */
  static readonly FinalizeTransferMediaPath = '/protected/transfers/{transferId}/media/{mediaId}/finalize';

  /**
   * Finalize transfer media.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `finalizeTransferMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  finalizeTransferMedia$Response(params: FinalizeTransferMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<TransferMedia>> {
    return finalizeTransferMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * Finalize transfer media.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `finalizeTransferMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  finalizeTransferMedia(params: FinalizeTransferMedia$Params, context?: HttpContext): Observable<TransferMedia> {
    return this.finalizeTransferMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransferMedia>): TransferMedia => r.body)
    );
  }

  /** Path part for operation `finalizeTransferPreview()` */
  static readonly FinalizeTransferPreviewPath = '/protected/transfers/{transferId}/preview/{previewId}/finalize ';

  /**
   * Finalize transfer preview.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `finalizeTransferPreview()` instead.
   *
   * This method doesn't expect any request body.
   */
  finalizeTransferPreview$Response(params: FinalizeTransferPreview$Params, context?: HttpContext): Observable<StrictHttpResponse<TransferMedia>> {
    return finalizeTransferPreview(this.http, this.rootUrl, params, context);
  }

  /**
   * Finalize transfer preview.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `finalizeTransferPreview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  finalizeTransferPreview(params: FinalizeTransferPreview$Params, context?: HttpContext): Observable<TransferMedia> {
    return this.finalizeTransferPreview$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransferMedia>): TransferMedia => r.body)
    );
  }

  /** Path part for operation `addTransfer()` */
  static readonly AddTransferPath = '/protected/transfers';

  /**
   * Add Transfer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTransfer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTransfer$Response(params?: AddTransfer$Params, context?: HttpContext): Observable<StrictHttpResponse<AddedTransfer>> {
    return addTransfer(this.http, this.rootUrl, params, context);
  }

  /**
   * Add Transfer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addTransfer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTransfer(params?: AddTransfer$Params, context?: HttpContext): Observable<AddedTransfer> {
    return this.addTransfer$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddedTransfer>): AddedTransfer => r.body)
    );
  }

  /** Path part for operation `getTransferPrice()` */
  static readonly GetTransferPricePath = '/private/transfers/price';

  /**
   * Get Transfer Price.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTransferPrice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getTransferPrice$Response(params?: GetTransferPrice$Params, context?: HttpContext): Observable<StrictHttpResponse<TransferPrice>> {
    return getTransferPrice(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Transfer Price.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTransferPrice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getTransferPrice(params?: GetTransferPrice$Params, context?: HttpContext): Observable<TransferPrice> {
    return this.getTransferPrice$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransferPrice>): TransferPrice => r.body)
    );
  }

  /** Path part for operation `verifyTransferSender()` */
  static readonly VerifyTransferSenderPath = '/protected/transfers/{transferId}/verifySender';

  /**
   * Verify Transfer Sender.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifyTransferSender()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyTransferSender$Response(params: VerifyTransferSender$Params, context?: HttpContext): Observable<StrictHttpResponse<Transfer>> {
    return verifyTransferSender(this.http, this.rootUrl, params, context);
  }

  /**
   * Verify Transfer Sender.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `verifyTransferSender$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyTransferSender(params: VerifyTransferSender$Params, context?: HttpContext): Observable<Transfer> {
    return this.verifyTransferSender$Response(params, context).pipe(
      map((r: StrictHttpResponse<Transfer>): Transfer => r.body)
    );
  }

  /** Path part for operation `payTransfer()` */
  static readonly PayTransferPath = '/protected/transfers/{transferId}/pay';

  /**
   * Pay Transfer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `payTransfer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  payTransfer$Response(params: PayTransfer$Params, context?: HttpContext): Observable<StrictHttpResponse<TransferPayUrl>> {
    return payTransfer(this.http, this.rootUrl, params, context);
  }

  /**
   * Pay Transfer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `payTransfer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  payTransfer(params: PayTransfer$Params, context?: HttpContext): Observable<TransferPayUrl> {
    return this.payTransfer$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransferPayUrl>): TransferPayUrl => r.body)
    );
  }

  /** Path part for operation `deleteTransferMedia()` */
  static readonly DeleteTransferMediaPath = '/private/transfers/{transferId}/media';

  /**
   * deleteTransferMedia.
   *
   * delete transfer media
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTransferMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTransferMedia$Response(params: DeleteTransferMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTransferMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * deleteTransferMedia.
   *
   * delete transfer media
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTransferMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTransferMedia(params: DeleteTransferMedia$Params, context?: HttpContext): Observable<void> {
    return this.deleteTransferMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `addTransferMedia()` */
  static readonly AddTransferMediaPath = '/private/transfers/{transferId}/media';

  /**
   * addTransferMedia.
   *
   * add media to Transfer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTransferMedia()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTransferMedia$Response(params: AddTransferMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<AddedTransfer>> {
    return addTransferMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * addTransferMedia.
   *
   * add media to Transfer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addTransferMedia$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTransferMedia(params: AddTransferMedia$Params, context?: HttpContext): Observable<AddedTransfer> {
    return this.addTransferMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddedTransfer>): AddedTransfer => r.body)
    );
  }

  /** Path part for operation `editCapabilities()` */
  static readonly EditCapabilitiesPath = '/private/transfers/{transferId}/capabilities';

  /**
   * editCapabilities.
   *
   * set transfer capabilities
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editCapabilities()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editCapabilities$Response(params: EditCapabilities$Params, context?: HttpContext): Observable<StrictHttpResponse<Transfer>> {
    return editCapabilities(this.http, this.rootUrl, params, context);
  }

  /**
   * editCapabilities.
   *
   * set transfer capabilities
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editCapabilities$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editCapabilities(params: EditCapabilities$Params, context?: HttpContext): Observable<Transfer> {
    return this.editCapabilities$Response(params, context).pipe(
      map((r: StrictHttpResponse<Transfer>): Transfer => r.body)
    );
  }

  /** Path part for operation `duplicateTransfer()` */
  static readonly DuplicateTransferPath = '/private/transfers/{transferId}/duplicate';

  /**
   * DuplicateTransfer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `duplicateTransfer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  duplicateTransfer$Response(params: DuplicateTransfer$Params, context?: HttpContext): Observable<StrictHttpResponse<AddedTransfer>> {
    return duplicateTransfer(this.http, this.rootUrl, params, context);
  }

  /**
   * DuplicateTransfer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `duplicateTransfer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  duplicateTransfer(params: DuplicateTransfer$Params, context?: HttpContext): Observable<AddedTransfer> {
    return this.duplicateTransfer$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddedTransfer>): AddedTransfer => r.body)
    );
  }

  /** Path part for operation `totalGain()` */
  static readonly TotalGainPath = '/private/transfers/gain';

  /**
   * get transfers total gain
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `totalGain()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  totalGain$Response(params?: TotalGain$Params, context?: HttpContext): Observable<StrictHttpResponse<TotalGain>> {
    return totalGain(this.http, this.rootUrl, params, context);
  }

  /**
   * get transfers total gain
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `totalGain$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  totalGain(params?: TotalGain$Params, context?: HttpContext): Observable<TotalGain> {
    return this.totalGain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TotalGain>): TotalGain => r.body)
    );
  }

}
