/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Config } from '../models/config';
import { readConfiguration } from '../fn/configuration/read-configuration';
import { ReadConfiguration$Params } from '../fn/configuration/read-configuration';

@Injectable({ providedIn: 'root' })
export class ConfigurationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readConfiguration()` */
  static readonly ReadConfigurationPath = '/public/configuration';

  /**
   * Your GET endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  readConfiguration$Response(params?: ReadConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<Config>> {
    return readConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Your GET endpoint.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readConfiguration(params?: ReadConfiguration$Params, context?: HttpContext): Observable<Config> {
    return this.readConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<Config>): Config => r.body)
    );
  }

}
