/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminBrowseReceivedTransfers } from '../fn/received-transfer/admin-browse-received-transfers';
import { AdminBrowseReceivedTransfers$Params } from '../fn/received-transfer/admin-browse-received-transfers';
import { browseReceivedTransfers } from '../fn/received-transfer/browse-received-transfers';
import { BrowseReceivedTransfers$Params } from '../fn/received-transfer/browse-received-transfers';
import { readReceivedTransfer } from '../fn/received-transfer/read-received-transfer';
import { ReadReceivedTransfer$Params } from '../fn/received-transfer/read-received-transfer';
import { ReceivedTransferList } from '../models/received-transfer-list';
import { Transfer } from '../models/transfer';

@Injectable({ providedIn: 'root' })
export class ReceivedTransferService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `browseReceivedTransfers()` */
  static readonly BrowseReceivedTransfersPath = '/private/receivedTransfers/browse';

  /**
   * Browse transfers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `browseReceivedTransfers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseReceivedTransfers$Response(params?: BrowseReceivedTransfers$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedTransferList>> {
    return browseReceivedTransfers(this.http, this.rootUrl, params, context);
  }

  /**
   * Browse transfers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `browseReceivedTransfers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseReceivedTransfers(params?: BrowseReceivedTransfers$Params, context?: HttpContext): Observable<ReceivedTransferList> {
    return this.browseReceivedTransfers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedTransferList>): ReceivedTransferList => r.body)
    );
  }

  /** Path part for operation `adminBrowseReceivedTransfers()` */
  static readonly AdminBrowseReceivedTransfersPath = '/admin/receivedTransfers/{userId}/browse';

  /**
   * Admin Browse transfers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminBrowseReceivedTransfers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminBrowseReceivedTransfers$Response(params: AdminBrowseReceivedTransfers$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedTransferList>> {
    return adminBrowseReceivedTransfers(this.http, this.rootUrl, params, context);
  }

  /**
   * Admin Browse transfers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminBrowseReceivedTransfers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminBrowseReceivedTransfers(params: AdminBrowseReceivedTransfers$Params, context?: HttpContext): Observable<ReceivedTransferList> {
    return this.adminBrowseReceivedTransfers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedTransferList>): ReceivedTransferList => r.body)
    );
  }

  /** Path part for operation `readReceivedTransfer()` */
  static readonly ReadReceivedTransferPath = '/private/receivedTransfers/{transferId}';

  /**
   * Read Received Transfer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readReceivedTransfer()` instead.
   *
   * This method doesn't expect any request body.
   */
  readReceivedTransfer$Response(params: ReadReceivedTransfer$Params, context?: HttpContext): Observable<StrictHttpResponse<Transfer>> {
    return readReceivedTransfer(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Received Transfer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readReceivedTransfer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readReceivedTransfer(params: ReadReceivedTransfer$Params, context?: HttpContext): Observable<Transfer> {
    return this.readReceivedTransfer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Transfer>): Transfer => r.body)
    );
  }

}
