/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addOauthClientCredentials } from '../fn/user/add-oauth-client-credentials';
import { AddOauthClientCredentials$Params } from '../fn/user/add-oauth-client-credentials';
import { adminUpdateUserCapability } from '../fn/user/admin-update-user-capability';
import { AdminUpdateUserCapability$Params } from '../fn/user/admin-update-user-capability';
import { browseUsers } from '../fn/user/browse-users';
import { BrowseUsers$Params } from '../fn/user/browse-users';
import { deleteMe } from '../fn/user/delete-me';
import { DeleteMe$Params } from '../fn/user/delete-me';
import { deleteOauthClientCredentials } from '../fn/user/delete-oauth-client-credentials';
import { DeleteOauthClientCredentials$Params } from '../fn/user/delete-oauth-client-credentials';
import { listOauthClientCredentials } from '../fn/user/list-oauth-client-credentials';
import { ListOauthClientCredentials$Params } from '../fn/user/list-oauth-client-credentials';
import { OauthClientCredentials } from '../models/oauth-client-credentials';
import { OauthClientCredentialsList } from '../models/oauth-client-credentials-list';
import { readMe } from '../fn/user/read-me';
import { ReadMe$Params } from '../fn/user/read-me';
import { readUser } from '../fn/user/read-user';
import { ReadUser$Params } from '../fn/user/read-user';
import { stripeOnboard } from '../fn/user/stripe-onboard';
import { StripeOnboard$Params } from '../fn/user/stripe-onboard';
import { updateUserBillingInfo } from '../fn/user/update-user-billing-info';
import { UpdateUserBillingInfo$Params } from '../fn/user/update-user-billing-info';
import { updateUserContactPreference } from '../fn/user/update-user-contact-preference';
import { UpdateUserContactPreference$Params } from '../fn/user/update-user-contact-preference';
import { updateUserLanguage } from '../fn/user/update-user-language';
import { UpdateUserLanguage$Params } from '../fn/user/update-user-language';
import { updateUserStorage } from '../fn/user/update-user-storage';
import { UpdateUserStorage$Params } from '../fn/user/update-user-storage';
import { User } from '../models/user';
import { UserList } from '../models/user-list';
import { userOnboard } from '../fn/user/user-onboard';
import { UserOnboard$Params } from '../fn/user/user-onboard';
import { userStripeDashboard } from '../fn/user/user-stripe-dashboard';
import { UserStripeDashboard$Params } from '../fn/user/user-stripe-dashboard';
import { UserStripeDashboardUrl } from '../models/user-stripe-dashboard-url';
import { UserStripeOnboardUrl } from '../models/user-stripe-onboard-url';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `browseUsers()` */
  static readonly BrowseUsersPath = '/admin/users/browse';

  /**
   * Browse users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `browseUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseUsers$Response(params?: BrowseUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<UserList>> {
    return browseUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Browse users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `browseUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseUsers(params?: BrowseUsers$Params, context?: HttpContext): Observable<UserList> {
    return this.browseUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserList>): UserList => r.body)
    );
  }

  /** Path part for operation `readUser()` */
  static readonly ReadUserPath = '/admin/users/{userId}';

  /**
   * Read User.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  readUser$Response(params: ReadUser$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return readUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Read User.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readUser(params: ReadUser$Params, context?: HttpContext): Observable<User> {
    return this.readUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `adminUpdateUserCapability()` */
  static readonly AdminUpdateUserCapabilityPath = '/admin/users/{userId}/capability';

  /**
   * AdminUpdateUserCapability.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUpdateUserCapability()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminUpdateUserCapability$Response(params: AdminUpdateUserCapability$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return adminUpdateUserCapability(this.http, this.rootUrl, params, context);
  }

  /**
   * AdminUpdateUserCapability.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUpdateUserCapability$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminUpdateUserCapability(params: AdminUpdateUserCapability$Params, context?: HttpContext): Observable<User> {
    return this.adminUpdateUserCapability$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `readMe()` */
  static readonly ReadMePath = '/private/users/me';

  /**
   * Read Me.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  readMe$Response(params?: ReadMe$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return readMe(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Me.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readMe(params?: ReadMe$Params, context?: HttpContext): Observable<User> {
    return this.readMe$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `deleteMe()` */
  static readonly DeleteMePath = '/private/users/me';

  /**
   * Delete Me.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMe$Response(params?: DeleteMe$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteMe(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Me.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMe(params?: DeleteMe$Params, context?: HttpContext): Observable<void> {
    return this.deleteMe$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stripeOnboard()` */
  static readonly StripeOnboardPath = '/private/users/me/stripe/onboard';

  /**
   * StripeUserOnboard.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stripeOnboard()` instead.
   *
   * This method doesn't expect any request body.
   */
  stripeOnboard$Response(params: StripeOnboard$Params, context?: HttpContext): Observable<StrictHttpResponse<UserStripeOnboardUrl>> {
    return stripeOnboard(this.http, this.rootUrl, params, context);
  }

  /**
   * StripeUserOnboard.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stripeOnboard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stripeOnboard(params: StripeOnboard$Params, context?: HttpContext): Observable<UserStripeOnboardUrl> {
    return this.stripeOnboard$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserStripeOnboardUrl>): UserStripeOnboardUrl => r.body)
    );
  }

  /** Path part for operation `listOauthClientCredentials()` */
  static readonly ListOauthClientCredentialsPath = '/private/users/me/oauth';

  /**
   * List OauthClientCredentials.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOauthClientCredentials()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOauthClientCredentials$Response(params?: ListOauthClientCredentials$Params, context?: HttpContext): Observable<StrictHttpResponse<OauthClientCredentialsList>> {
    return listOauthClientCredentials(this.http, this.rootUrl, params, context);
  }

  /**
   * List OauthClientCredentials.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOauthClientCredentials$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOauthClientCredentials(params?: ListOauthClientCredentials$Params, context?: HttpContext): Observable<OauthClientCredentialsList> {
    return this.listOauthClientCredentials$Response(params, context).pipe(
      map((r: StrictHttpResponse<OauthClientCredentialsList>): OauthClientCredentialsList => r.body)
    );
  }

  /** Path part for operation `addOauthClientCredentials()` */
  static readonly AddOauthClientCredentialsPath = '/private/users/me/oauth';

  /**
   * Add OauthClientCredentials.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOauthClientCredentials()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOauthClientCredentials$Response(params?: AddOauthClientCredentials$Params, context?: HttpContext): Observable<StrictHttpResponse<OauthClientCredentials>> {
    return addOauthClientCredentials(this.http, this.rootUrl, params, context);
  }

  /**
   * Add OauthClientCredentials.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addOauthClientCredentials$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOauthClientCredentials(params?: AddOauthClientCredentials$Params, context?: HttpContext): Observable<OauthClientCredentials> {
    return this.addOauthClientCredentials$Response(params, context).pipe(
      map((r: StrictHttpResponse<OauthClientCredentials>): OauthClientCredentials => r.body)
    );
  }

  /** Path part for operation `deleteOauthClientCredentials()` */
  static readonly DeleteOauthClientCredentialsPath = '/private/users/me/oauth/{clientId}';

  /**
   * Delete OauthClientCredentials.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOauthClientCredentials()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOauthClientCredentials$Response(params: DeleteOauthClientCredentials$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteOauthClientCredentials(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete OauthClientCredentials.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOauthClientCredentials$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOauthClientCredentials(params: DeleteOauthClientCredentials$Params, context?: HttpContext): Observable<void> {
    return this.deleteOauthClientCredentials$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userStripeDashboard()` */
  static readonly UserStripeDashboardPath = '/private/users/me/stripe/dashboard';

  /**
   * User Stripe Dashboard.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userStripeDashboard()` instead.
   *
   * This method doesn't expect any request body.
   */
  userStripeDashboard$Response(params?: UserStripeDashboard$Params, context?: HttpContext): Observable<StrictHttpResponse<UserStripeDashboardUrl>> {
    return userStripeDashboard(this.http, this.rootUrl, params, context);
  }

  /**
   * User Stripe Dashboard.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userStripeDashboard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userStripeDashboard(params?: UserStripeDashboard$Params, context?: HttpContext): Observable<UserStripeDashboardUrl> {
    return this.userStripeDashboard$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserStripeDashboardUrl>): UserStripeDashboardUrl => r.body)
    );
  }

  /** Path part for operation `updateUserLanguage()` */
  static readonly UpdateUserLanguagePath = '/private/users/me/language';

  /**
   * Update User Language.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserLanguage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserLanguage$Response(params?: UpdateUserLanguage$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return updateUserLanguage(this.http, this.rootUrl, params, context);
  }

  /**
   * Update User Language.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserLanguage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserLanguage(params?: UpdateUserLanguage$Params, context?: HttpContext): Observable<User> {
    return this.updateUserLanguage$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `updateUserContactPreference()` */
  static readonly UpdateUserContactPreferencePath = '/private/users/me/contactPreference';

  /**
   * Update User ContactPreference.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserContactPreference()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserContactPreference$Response(params?: UpdateUserContactPreference$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return updateUserContactPreference(this.http, this.rootUrl, params, context);
  }

  /**
   * Update User ContactPreference.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserContactPreference$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserContactPreference(params?: UpdateUserContactPreference$Params, context?: HttpContext): Observable<User> {
    return this.updateUserContactPreference$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `updateUserStorage()` */
  static readonly UpdateUserStoragePath = '/admin/users/{userId}/storage';

  /**
   * UpdateUserStorage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserStorage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserStorage$Response(params: UpdateUserStorage$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return updateUserStorage(this.http, this.rootUrl, params, context);
  }

  /**
   * UpdateUserStorage.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserStorage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserStorage(params: UpdateUserStorage$Params, context?: HttpContext): Observable<User> {
    return this.updateUserStorage$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `updateUserBillingInfo()` */
  static readonly UpdateUserBillingInfoPath = '/private/users/me/billingInfo';

  /**
   * Update Billing Info.
   *
   * add/update billing info to user entity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserBillingInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserBillingInfo$Response(params?: UpdateUserBillingInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return updateUserBillingInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Billing Info.
   *
   * add/update billing info to user entity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserBillingInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserBillingInfo(params?: UpdateUserBillingInfo$Params, context?: HttpContext): Observable<User> {
    return this.updateUserBillingInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `userOnboard()` */
  static readonly UserOnboardPath = '/private/users/me/onboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userOnboard()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userOnboard$Response(params?: UserOnboard$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return userOnboard(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userOnboard$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userOnboard(params?: UserOnboard$Params, context?: HttpContext): Observable<User> {
    return this.userOnboard$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

}
