/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminBrowseAffiliations } from '../fn/affiliation/admin-browse-affiliations';
import { AdminBrowseAffiliations$Params } from '../fn/affiliation/admin-browse-affiliations';
import { adminReadAffiliation } from '../fn/affiliation/admin-read-affiliation';
import { AdminReadAffiliation$Params } from '../fn/affiliation/admin-read-affiliation';
import { Affiliate } from '../models/affiliate';
import { Affiliation } from '../models/affiliation';
import { AffiliationList } from '../models/affiliation-list';
import { joinAffiliation } from '../fn/affiliation/join-affiliation';
import { JoinAffiliation$Params } from '../fn/affiliation/join-affiliation';
import { leaveAffiliation } from '../fn/affiliation/leave-affiliation';
import { LeaveAffiliation$Params } from '../fn/affiliation/leave-affiliation';
import { readAffiliation } from '../fn/affiliation/read-affiliation';
import { ReadAffiliation$Params } from '../fn/affiliation/read-affiliation';

@Injectable({ providedIn: 'root' })
export class AffiliationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminBrowseAffiliations()` */
  static readonly AdminBrowseAffiliationsPath = '/admin/affiliations/browse';

  /**
   * Admin browse affiliations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminBrowseAffiliations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminBrowseAffiliations$Response(params?: AdminBrowseAffiliations$Params, context?: HttpContext): Observable<StrictHttpResponse<AffiliationList>> {
    return adminBrowseAffiliations(this.http, this.rootUrl, params, context);
  }

  /**
   * Admin browse affiliations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminBrowseAffiliations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminBrowseAffiliations(params?: AdminBrowseAffiliations$Params, context?: HttpContext): Observable<AffiliationList> {
    return this.adminBrowseAffiliations$Response(params, context).pipe(
      map((r: StrictHttpResponse<AffiliationList>): AffiliationList => r.body)
    );
  }

  /** Path part for operation `adminReadAffiliation()` */
  static readonly AdminReadAffiliationPath = '/admin/affiliations/{id}';

  /**
   * Admin read Affiliation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminReadAffiliation()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminReadAffiliation$Response(params: AdminReadAffiliation$Params, context?: HttpContext): Observable<StrictHttpResponse<Affiliation>> {
    return adminReadAffiliation(this.http, this.rootUrl, params, context);
  }

  /**
   * Admin read Affiliation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminReadAffiliation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminReadAffiliation(params: AdminReadAffiliation$Params, context?: HttpContext): Observable<Affiliation> {
    return this.adminReadAffiliation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Affiliation>): Affiliation => r.body)
    );
  }

  /** Path part for operation `readAffiliation()` */
  static readonly ReadAffiliationPath = '/private/affiliations';

  /**
   * Read Affiliation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readAffiliation()` instead.
   *
   * This method doesn't expect any request body.
   */
  readAffiliation$Response(params?: ReadAffiliation$Params, context?: HttpContext): Observable<StrictHttpResponse<Affiliation>> {
    return readAffiliation(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Affiliation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readAffiliation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readAffiliation(params?: ReadAffiliation$Params, context?: HttpContext): Observable<Affiliation> {
    return this.readAffiliation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Affiliation>): Affiliation => r.body)
    );
  }

  /** Path part for operation `joinAffiliation()` */
  static readonly JoinAffiliationPath = '/private/affiliations/join';

  /**
   * Join Affiliation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `joinAffiliation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  joinAffiliation$Response(params?: JoinAffiliation$Params, context?: HttpContext): Observable<StrictHttpResponse<Affiliate>> {
    return joinAffiliation(this.http, this.rootUrl, params, context);
  }

  /**
   * Join Affiliation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `joinAffiliation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  joinAffiliation(params?: JoinAffiliation$Params, context?: HttpContext): Observable<Affiliate> {
    return this.joinAffiliation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Affiliate>): Affiliate => r.body)
    );
  }

  /** Path part for operation `leaveAffiliation()` */
  static readonly LeaveAffiliationPath = '/private/affiliations/leave';

  /**
   * Leave Affiliation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaveAffiliation()` instead.
   *
   * This method doesn't expect any request body.
   */
  leaveAffiliation$Response(params?: LeaveAffiliation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return leaveAffiliation(this.http, this.rootUrl, params, context);
  }

  /**
   * Leave Affiliation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaveAffiliation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  leaveAffiliation(params?: LeaveAffiliation$Params, context?: HttpContext): Observable<void> {
    return this.leaveAffiliation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
