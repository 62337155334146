/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Payment } from '../models/payment';
import { verifyPayment } from '../fn/payment/verify-payment';
import { VerifyPayment$Params } from '../fn/payment/verify-payment';

@Injectable({ providedIn: 'root' })
export class PaymentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `verifyPayment()` */
  static readonly VerifyPaymentPath = '/protected/payments/{transferId}/verify';

  /**
   * Verify Transfer Payment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifyPayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyPayment$Response(params: VerifyPayment$Params, context?: HttpContext): Observable<StrictHttpResponse<Payment>> {
    return verifyPayment(this.http, this.rootUrl, params, context);
  }

  /**
   * Verify Transfer Payment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `verifyPayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyPayment(params: VerifyPayment$Params, context?: HttpContext): Observable<Payment> {
    return this.verifyPayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Payment>): Payment => r.body)
    );
  }

}
