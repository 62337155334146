/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Browsing } from '../../models/browsing';
import { TransferList } from '../../models/transfer-list';

export interface AdminBrowseTransfersByUser$Params {
  userId: string;
      body?: Browsing
}

export function adminBrowseTransfersByUser(http: HttpClient, rootUrl: string, params: AdminBrowseTransfersByUser$Params, context?: HttpContext): Observable<StrictHttpResponse<TransferList>> {
  const rb = new RequestBuilder(rootUrl, adminBrowseTransfersByUser.PATH, 'post');
  if (params) {
    rb.path('userId', params.userId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TransferList>;
    })
  );
}

adminBrowseTransfersByUser.PATH = '/admin/transfers/{userId}/browse';
