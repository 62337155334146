/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PayTransfer } from '../../models/pay-transfer';
import { TransferPayUrl } from '../../models/transfer-pay-url';

export interface PayTransfer$Params {
  transferId: string;
      body?: PayTransfer
}

export function payTransfer(http: HttpClient, rootUrl: string, params: PayTransfer$Params, context?: HttpContext): Observable<StrictHttpResponse<TransferPayUrl>> {
  const rb = new RequestBuilder(rootUrl, payTransfer.PATH, 'post');
  if (params) {
    rb.path('transferId', params.transferId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TransferPayUrl>;
    })
  );
}

payTransfer.PATH = '/protected/transfers/{transferId}/pay';
